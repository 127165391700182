<template>
    <div class="initiationApply">
       <moduleHead module_head_title="入会申请基本资料" @more_func="XSFC_more_func"></moduleHead>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">参会代表人姓名</div>
        <input id="name" type="text" v-model="data['name']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">参会企业单位名称</div>
        <input id="companyName" type="text"  v-model="data['companyName']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">参会联系方式</div>
        <input id="contactWay" type="text" v-model="data['contactWay']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">所属团体</div>
        <input id="theGroups" type="text"  v-model="data['theGroups']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">学历</div>
        <input id="education" type="text"  v-model="data['education']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">政治面貌</div>
        <input id="politicsStatus" type="text"  v-model="data['politicsStatus']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">社会职务</div>
        <input id="socialTitle" type="text" v-model="data['socialTitle']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">籍贯</div>
        <input id="nativePlace" type="text"  v-model="data['nativePlace']" placeholder="请输入">
       </div>
       <div class="initiationApply_div">
        <div class="initiationApply_div_title">个人简介</div>
        <van-cell-group inset>
            <van-field
            id="individualResume"
            v-model="data['individualResume']"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请输入"
            show-word-limit
            />
        </van-cell-group>
       </div>

       <van-button style="margin-bottom:20px;" round type="success" color="#ee0a24" @click="submit">提交资料</van-button>
       <!-- <el-button type="primary">Primary</el-button> -->
    </div>
</template>

<script>
import {
  defineComponent,reactive,Common
} from "@/plugin/importCommonUse";
import { useRouter } from "vue-router";
import moduleHead from "@/components/moduleHead/index.vue"
import {toRegister} from "@/api/data"
export default defineComponent({
    components:{
        moduleHead,
    },
    setup() {
        let router = useRouter();
        let data = reactive({
            name:"",//参会代表人姓名
            companyName:"",//参会企业单位名称
            contactWay:"",//参会联系方式
            theGroups:"",//所属团体
            education:"",//学历
            politicsStatus:"",//政治面貌
            socialTitle:"",//社会职务
            nativePlace:"",//籍贯
            individualResume:""//个人简介

        })
        let skip = (url)=>{
            router.push(url);
        }
        let submit = ()=>{
            verifyNull(data);
        }
        let verifyNull = (res)=>{
            let Dictionary= {
                name:"参会代表人姓名",
                companyName:"参会企业单位名称",
                contactWay:"参会联系方式",
                theGroups:"所属团体",
                education:"学历",
                politicsStatus:"政治面貌",
                socialTitle:"社会职务",
                nativePlace:"籍贯",
                individualResume:"个人简介"
            }
            for (let key in res) {
                if(res[key] === ""){ 
                    console.log(res[key],Dictionary[key])
                    document.getElementById(key).focus();
                    Common.Fail('请输入'+Dictionary[key]);
                    return ;
                }
            }
            Common.Load(); 
            toRegister({
                name:res["name"],
                team:res["theGroups"],
                mobile:res["contactWay"],
                company:res["companyName"],
                education:res["education"],
                political_outlook:res["politicsStatus"],
                job:res["socialTitle"],
                native_place:res["nativePlace"],
                personal_profile:res["individualResume"]
            }).then((e)=>{
                if (e.data.code == 0) {
                    Common.Fail(e.data.msg);
                    return;
                }
                Common.Suc(e.data.msg,2000,()=>{
                    data = {}
                    router.push("/");
                });
            })
        }

        return {
            skip,data,submit
        };
    },
})
</script>
<style>
.initiationApply{
    width: 100%;
    /* border-bottom: 1px solid #dcdee2; */
}
.initiationApply_div{
    text-align: left;
    margin: 10px 10px 20px 10px;
}
.initiationApply_div_title{
    margin-bottom: 10px;
    font-size: 15px;
}
.initiationApply_div input{
    border: 1px solid #E8E8E8;
    border-radius: 15px;
    height: 30px;
    width: 100%;
    padding: 10px;
    box-sizing:border-box;
  -moz-box-sizing:border-box; /* Firefox */
  -webkit-box-sizing:border-box; /* Safari */
}
::-webkit-input-placeholder{/*Webkit browsers*/
	color:#C2C2C2;
	font-size:14px;
}
:-moz-placeholder{/*Mozilla Firefox 4 to 8*/
	color:#C2C2C2;
	font-size:14px;
}
::moz-placeholder{/*Mozilla Firefox 19+*/
	color:#C2C2C2;
	font-size:14px;
}
:-ms-input-placeholder{/*Internet Explorer 10+*/
    color:#C2C2C2;
    font-size:14px;
}
input:focus-within {		
    border: 1px solid #B34136;	
}
.van-cell-group--inset{
    margin: 0px;
    border: 1px solid #E8E8E8;
}
 .van-cell-group--inset:focus-within{
    border: 1px solid #B34136;	
}
.van-cell{
    padding:10px;
}
</style>
